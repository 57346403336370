.product-carousel-container {
  text-align: left;
  padding: 20px 20px 20px 20px;
  position: relative; /* Position arrows relative to this container */
  @media only screen and (max-width: 760px) {
    padding: 0px;
  }
}

.carousel-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 50px;
  margin-left: 30px;
  position: relative; /* Ensure title is properly positioned */
  @media only screen and (max-width: 760px) {
    margin-left: 0px;
  }
}

.carousel-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  margin-left: 30px;
  position: relative; /* Ensure subtitle is properly positioned */
  @media only screen and (max-width: 760px) {
    margin-left: 0px;
  }
}


.product-card {
  margin: 0 20px; /* Increase the margin here to add more space between products */
  width: calc(100% - 40px); /* Adjust width to accommodate the new margin */
  overflow: hidden;
  text-align: center;
  background-color: #fff;
}

.product-image {
  width: 100%;
  height: auto;
}

.carousel-info {
  padding: 15px;
}

.carousel-name {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
}

.carousel-price {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}

.add-to-cart-btn {
  display: inline-block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.add-to-cart-btn:hover {
  background-color: #f8f8f8;
}

.carousel-arrow {
  position: absolute;
  overflow: visible;
  top: 100px; /* Adjust this value to position the arrows */
  z-index: 10; /* Ensure the arrows are above other elements */
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 760px) {
    top: 40px;
  }
}

.carousel-arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.carousel-arrow.left {
  right: 60px; /* Adjust this value based on your needs */
  overflow: visible;
}

.carousel-arrow.right {
  right: 20px;
  overflow: visible;
}

.custom-dot {
  background-color: #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 5px;
}

.custom-dot-active {
  background-color: #333;
}

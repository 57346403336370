body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}

/* home screen background */
.homescreen-background-1 {
  /* background-image: url("https://i.imgur.com/y4halFr.jpeg"); */
  background-image: url("https://i.imgur.com/kS4lPGJ.jpeg");
  background-size: cover;
  background-position: center bottom;
  height: 101vh;
  background-color: #EBE7E1;
  background-repeat: no-repeat;
  /* Optionally, you can set other background properties like repeat or attachment */
  @media only screen and (max-width: 760px) {
    /* background-image: url("https://i.imgur.com/kbZtD7c.jpg"); */
    background-image: url("https://i.imgur.com/AV3fHgu.jpeg");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 100vh;
    /* Make the background image scale down to fit the container */
    /* Optionally, adjust other background properties as needed */
  }

  @media only screen and (min-width: 760px) and (max-width: 840px) {
    background-image: url("https://i.imgur.com/juAt9SV.jpeg");
  }
}

.homescreen-background-1 img{
  object-fit: fill;
}

.homescreen-custom-height-1 {
  display: flex;
  width: 100%;
  @media only screen and (max-width: 760px) {
    height: auto;
    flex-direction: column-reverse;
  }
}

.homescreen-custom-height-1 img {
  object-fit: fill;
}
.homescreen-custom-height-2 {
  display: flex;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 760px) {
    height: auto;
    flex-direction: column;
  }
}

.custom-carousel {
  height: 100%;
  flex: 1;
  @media only screen and (max-width: 760px) {
    height: auto;
    flex: none;
  }
}

.custom-carousel {
  padding-left: 0px;
  width: 100%;
  height: 100%;
  margin-top: -40px;
  max-width: 50vw; /* Maximum width of the carousel */
  max-height: 100vh; /* Fixed height of the carousel */

}

@media only screen and (max-width: 760px) {
  .custom-carousel {
      max-width: 100vw;
      padding-left: 20px;
      margin-bottom: 100px;
      margin-top: 0px;
  }
}

.right-carousel-image {
  flex: 1;
  max-width: 50vw;
  max-height: 70vw;
  display: flex;
  justify-content: flex-end;
  box-sizing: content-box;
  @media only screen and (max-width: 760px) {
    justify-content: center;
    max-width: 100vw;
    max-height: 100%;
    /* border-top: 5px solid #e4e4e4; */
  }
}

.right-carousel-image img {
  max-width: 100%;
  height: 100%;
  object-fit: fill;
  @media only screen and (max-width: 760px) {
    height: 500px;
    width: auto;
    max-width: 100%;
    object-fit: cover;
  }
}

.homescreen-explore-text {
  color: black;
  width: 500px;
  font-size: 2rem;
  font-weight: bolder;
  margin-left: 150px;
  margin-top: 200px;
  margin-bottom: 20px; /* Adjust margin to control vertical spacing */
  text-align: left; /* Center-align the text */
  @media only screen and (max-width: 760px) {
    width: 290px;
    margin-left: 40px;
    margin-top: 30px;
  }
}

.homescreen-shop-now-button {
  background: #6a1a30;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-top: -10px;
  margin-left: 170px;
  padding: 10px 50px;
  text-transform: uppercase;
  font-size: 24px;

  @media only screen and (max-width: 760px) {

    margin-left: 40px;
    font-weight: bold;
    font-size: 27px;
  }
}

.homescreen-shop-now-button:hover {
  color: #fff;
}

.text-outline {
  
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Add a shadow to create the outline effect */
}

.alert-info {
  color: #fff;
  background-color: #e7e0cd;
  border-color: #e7e0cd;
}

.btn-group .name-button {
  border: none;
  /* or */
  /* border: 0; */
}

.btn-success {
  color: #fff;
  background-color: #6a1a30;
  border-color: #6a1a30;
}

.btn-success:hover {
  color: #fff;
  background-color: #6a1a30;
  border-color: #6a1a30;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #6a1a30 !important;
    background-color: #6a1a30 !important;
    border-color: #6a1a30 !important;
    box-shadow: none !important; /* Remove the box shadow */
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: rgb(0, 0, 0);
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}
.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}
.Announcement {
  background-color: #6a1a30;
  overflow: hidden;
  padding: 10px 0px 6px 0px;
  position: relative;
  width: 100vw;
  max-width: 100vw;
}
.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}
.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}

.announcement-text {
  white-space: nowrap;
  display: inline-block;
  font-size: large;
  color: white;
  animation: scroll-right 25s linear infinite;
  padding-left: 40%;
  @media only screen and (max-width: 760px) {
    animation: scroll-right 10s linear infinite;
    padding-left: 5%;
  }
}

@keyframes scroll-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* Header */
.header {
  padding: 10px 0px 10px 0px;
  @media only screen and (min-width: 760px) and (max-width: 840px) {
    padding: 10px 0px 10px 120px;
  }
}

.header .menu-btn {
  padding: 0;
  font-size: 30px;
  scale: 1.5;
  background: none;
  border: none;
  color: #6a1a30;
  cursor: pointer;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.header .menu-btn:hover {
  transform: scale(1.1);
}

.header .menu-btn:focus {
  outline: none;
}

.header .menu-btn:active {
  transform: scale(0.95);
}

.navbar-brand {
  @media only screen and (max-width: 760px) {
    position: absolute;
    left: 43%;
    top: 47px;
  }
}

.navbar-brand img {
  width: 60%;
  scale: 1.2;
}
.input-group {
  height: 50px;
  width: 90%;
}
.search-button {
  width: 20%;
  background-color: black;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
}
.Login-Register a {
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 2rem;
  position: relative;
}
.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}
.badge {
  background-color: red;
  position: absolute;
  top: -20px;
  border-radius: 100px;
  padding: 5px 7px;
  font-size: 12px;
}

.name-button {
  color: black;
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: transparent;
  border-radius: 2px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: black !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 100px !important;
}
.dropdown-menu.show {
  width: 100%;
  max-width: 10px;
}
.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}
.dropdown-menu a:hover {
  background-color: #6a1a30 !important;
  color: #ffffff !important;
}
.dropdown-item {
  max-width: 110px; /* Adjust width as needed */
  white-space: normal !important;
}
.mobile-header {
  display: none;
}

/* SHOP */

.home-button {
  background-color: #6a1a30 !important;
  color: white;
  border-color: #6a1a30 !important;
}

.home-button a {
  background-color: #6a1a30 !important;
  border-color: #6a1a30 !important;
  color: white !important;
}

.home-button:hover {
  background-color: #6a1a30 !important;
  color: white !important;
  border-color: #6a1a30 !important;
}

.home-button:focus {
  border-color: #6a1a30 !important;
  color: white !important;
}

.section {
  width: 100%;
  padding-bottom: 6%;
  padding-top: 2%;
}
.sidebar_item {
  margin-bottom: 35px;
}
.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
.sidebar_item ul {
  margin: 0;
  padding: 0;
}
.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}
.shop {
  margin-bottom: 50px;
}
.border-product {
  padding: 15px;
  /*border: 1px solid #f3f3f3;*/
  border-radius: 5px;
}
.shopBack {
  position: relative;
  overflow: hidden;
  width: 85%;
  background-color: #EBE7E1;
}
.shopBack img {
  width: 100%;
  object-fit: contain;
}
.shoptext {
  padding-top: 10px;
}
.shoptext p a {
  color: #252525;
}
.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}
.rating {
  margin: 5px 0;
}
.rating i {
  font-size: 12px;
  color: #ebb450;
}
.rating span {
  margin-left: 10px;
}
/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)),
    url("https://sasshoes-com.s3.us-west-2.amazonaws.com/img/banner-uplift-21.jpg")
      no-repeat scroll center / cover;
  padding: 100px 0 100px;
}
.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}
.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}
.subscribe-head {
  text-align: center;
}
.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}
.form-section input[type="submit"] {
  background: #6a1a30;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}
/* CONTACT */
.contactInfo {
  padding: 70px 0;
  margin-top:40px;
}
.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}
.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #6a1a30;
  border-radius: 100px;
  font-size: 20px;
  color: #6a1a30;
}
.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}
.box-info p {
  font-size: 16px;
}

/* SINGLE PRODUCT */
.single-product {
  margin-top: 50px;
  margin-bottom: 50px;
}
.single-image {
  background-color: transparent;
  margin-right: 20px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-review-average {
  padding-top: 10px;
  font-size: medium;
  display: flex;
  align-items: center;
}

.product-review-average > * {
  margin-right: 5px; /* Adjust the value as needed for spacing */
}

.product-review-average .btn-link:focus {
  box-shadow: none;
}

.product-review-average i {
  color: #ebb450;
  padding-right: 5px;
}

@media only screen and (max-width: 760px){
  .reviews-section {
      margin-top: 30px;
      order: 2;
  }
  .write-review-section {
      order: 1;
  }
}

.paginate-btn {
  background-color: black;
  color: white;
}

.paginate-btn:hover {
  background-color: #6a1a30;
  color: white !important;
}

.paginate-btn:focus {
  box-shadow: none !important;
}

.carousel.slide {
  margin-top: 10%;
}

.img-carousel {
  margin-top: 7%;
  @media only screen and (max-width: 760px){
    width: 100%;
  }
}

.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
  
}
.product-name {
  font-size: 35px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}
.product-info {
  width: 100%;
}
.product-dtl p {
  line-height: 24px;
  color: #7a7a7a;
}
.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}
.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}
.product-count .flex-box h6 {
  font-size: 16px;
}
.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}
.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}
.round-black-btn {
  border-radius: 4px;
  background: #000000;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
  background: #6a1a30;
}

.quantity-control {
  display: flex;
  align-items: center;
  border: 1px solid #ddd; /* Outer border */
  border-radius: 4px;
  overflow: hidden; /* Ensure child elements don't overflow the border radius */
}

.quantity-control span {
  width: 1.4em;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.qty-button {
  width: 2.5em; /* Fixed width for the button */
  height: 2.5em; /* Fixed height for the button */
  font-size: 1em; /* Base font size for the button */
  border: none;
  background-color: #fff; /* Button background color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-button .symbol {
  font-size: 1.5em; /* Make the - and + symbols larger */
  line-height: 1; /* Adjust line-height to avoid vertical stretching */
}

.quantity-control .quantity {
  width: 2.5em; /* Fixed width for the quantity display */
  text-align: center;
  font-size: 1em; /* Font size for the quantity display */
  background-color: #fff; /* Background color for the quantity display */
  border-left: 1px solid #ddd; /* Border between the buttons and the quantity display */
  border-right: 1px solid #ddd; /* Border between the buttons and the quantity display */
}

@media (max-width: 760px) {
  .qty-button {
    width: 2em; /* Adjust width for smaller screens */
    height: 2em; /* Adjust height for smaller screens */
    font-size: 0.875em; /* Adjust base font size for smaller screens */
  }

  .qty-button .symbol {
    font-size: 1.2em; /* Adjust symbol size for smaller screens */
  }

  .quantity-control .quantity {
    width: 2em; /* Adjust width for smaller screens */
    height: 2em; /* Adjust height for smaller screens */
    font-size: 1.2em; /* Adjust quantity font size for smaller screens */
  }
}

/* OPTION */
.option-control {
  display: flex;
  align-items: center;
  border: 1px solid #ddd; /* Outer border */
  border-radius: 4px;
  overflow: hidden; /* Ensure child elements don't overflow the border radius */
}

.option-control span {
  width: 1.4em;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.opt-button {
  width: 2.5em; /* Fixed width for the button */
  height: 2.5em; /* Fixed height for the button */
  font-size: 1em; /* Base font size for the button */
  border: 1px solid #ddd; /* Separate border for each button */
  background-color: #fff; /* Button background color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px; /* Overlap the borders */
}

.opt-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0; /* Remove margin for the first button */
}

.opt-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.opt-button .symbol {
  font-size: 1.5em; /* Make the - and + symbols larger */
  line-height: 1; /* Adjust line-height to avoid vertical stretching */
}

.opt-button.active {
  background-color: lightgray; /* Highlight color for the active button */
  color: #fff; /* Text color for the active button */
}

.opt-button.disabled {
  opacity: 0.5; /* Set opacity for disabled state */
  pointer-events: none; /* Disable pointer events to prevent interaction */
}

@media (max-width: 760px) {
  .opt-button {
    width: 2em; /* Adjust width for smaller screens */
    height: 2em; /* Adjust height for smaller screens */
    font-size: 0.875em; /* Adjust base font size for smaller screens */
  }

  .opt-button .symbol {
    font-size: 1.2em; /* Adjust symbol size for smaller screens */
  }
}

/* LOGIN */
.login-center {
  padding: 50px 0;
}
.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
}
.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}
.Login input:focus {
  outline: 0px;
}
.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #6a1a30;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}
.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}
.Login p {
  margin-top: 30px;
}
.Login p a {
  color: #7a7a7a;
}
/* PROFILE */
.author-card {
  position: relative;
}
.author-card-cover {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-image: url("https://gust.com/assets/blank_slate/Gust_Profile_CoverPhoto_Blank-21edf1e2890708d5a507204f49afc10b7dc58eb7baea100b68a1bc2c96948297.png");
}
.author-card-profile {
  padding: 20px 10px;
}
.author-card-avatar {
  padding: 0 20px;
}
.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -60%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}
.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}
.wizard .nav .active {
  background-color: #e7e0cd;
  color: black;
}
.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}
.form {
  padding: 20px 0;
}
.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}
.form input {
  padding: 20px 10px;
  font-size: 17px;
  background-color: #e7e0cd;
}
.form-container button {
  padding: 15px 0;
  border: 0px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #6a1a30;
  color: #ffffff;
}
/* orders */
.link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}
.link:hover {
  color: blue;
}
.table-responsive {
  width: 100%;
}
/* CART */

.cart-update-button {
  background-color: #6a1a30 !important;
  border-color: #6a1a30 !important;
  box-shadow: none !important;
  width: 67%;
  @media only screen and (max-width: 760px) {
    width: 50%;
  }

  @media only screen and (min-width: 760px) and (max-width: 840px) {
    width: 100%;
  }

}

.cart-total-num {
  background-color: #e7e0cd;
  border-color: #e7e0cd;
  color: #fff;
}

.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}

.remove-button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  @media only screen and (max-width: 760px) {
    margin-bottom: 20px;
  }
}
.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}
.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}
.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}
.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}
.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}
.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}
.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.cart-buttons {
  margin: 90px 0;
}
.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #6a1a30;
  color: #ffffff;
}
.cart-buttons a:nth-child(1) button {
  background-color: #e7e0cd;
}

.btn-guest-checkout {
  background-color: #6a1a30 !important;
}
/* FOOTER */
.footer {
  background-color: #ececec;
}
.card-name {
  width: 4%;
  margin: 10px;
}
.card-name img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

/* Payment */
.payment-container {
  margin-top: 20px;
}
.radio-container {
  padding: 10px 0;
  text-align: start;
}
.radio-container input {
  font-size: 17px;
}
.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}
.order-detail p {
  margin-top: 2px;
}
.order-detail {
  padding: 30px 0;
  background-color: #e7e0cd;
}
.order-detail h5 {
  margin-bottom: 6px;
}

.alert-success {
  color: #6a1a30;
  background-color: #f5efdf;
  border-color: #badbcc;
}

.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}
.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.table-bordered {
  background-color: #f3f3f3;
}
.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #6a1a30;
  color: #ffffff;
}

.bg-info1 {
  background-color: #6a1a30;
}

/* FORGOT PASSWORD PAGE */
.reset-password-btn.fade-out {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; /* Makes the button unclickable */
}

/* MOBILE SIDEBAR */

.sidebar {
  position: fixed;
  top: 0;
  left: -85%;
  width: 85%;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  transition: left 0.3s ease-in-out;
  z-index: 1001;
  padding-top: 60px;
}

.sidebar.open {
  left: 0;
}

/* Make sidebar visible only on mobile */
@media (max-width: 760px) {
  .sidebar {
    display: block;
  }
}

.sidebar-header {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #6a1a30;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  overflow-x: hidden;
}

.sidebar-header .close-btn {
  font-size: 30px;
  scale: 2;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-categories {
  display: flex;
  flex-direction: column; /* Make categories vertical */
  padding-top: 5px;
  background-color: #f4f4f4;
}

.sidebar-categories .category-btn {
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  text-align: left; /* Align text to the left */
  padding: 10px;
}

.sidebar-categories .category-btn::after {
  content: '▼'; /* Down arrow */
  font-size: 12px;
  position: absolute;
  right: 10px; /* Position to the far right */
}

.sidebar-categories .category-btn.active::after {
  content: '▲'; /* Up arrow when active */
}

.sidebar-categories .category-btn.active {
  color: #6a1a30;
  border-left: 2px solid #6a1a30; /* Change to left border for vertical alignment */
}

.sidebar-subcategories {
  padding: 0 20px; /* Adjust padding to fit with the new structure */
  background-color: white;
}

.subcategory-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.subcategory-menu li {
  padding: 10px 0;
}

.subcategory-menu li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  display: block;
}

.subcategory-menu li a:hover {
  background-color: #f4f4f4;
}

body.sidebar-open,
html.sidebar-open {
  overflow: hidden;
}

body.sidebar-open .main-content {
  pointer-events: none; /* Disable interactions */
}

body.sidebar-open .sidebar {
  pointer-events: all;
}

.main-content {
  transition: opacity 0.3s ease-in-out;
}

/* Pagination */
.pagination .page-link {
  padding: 10px 30px;
  color: black;
}
.page-item.active .page-link {
  background-color: black;
  border: 1px solid black;
}
